import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image";

const Timeline = () => {

  const TimelineData = useStaticQuery(graphql`
    query TimelineQuery {
      allFile(
        filter: { relativeDirectory: { eq: "images/banner/companies" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed(quality: 100, height: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const companies = TimelineData.allFile.edges.map(edge => ({
    name: edge.node.name,
    image: edge.node.childImageSharp.fixed,
  }))

  const getImages = name =>
    companies.filter(company => company.name === name)?.[0]?.image

  return (
    <div id="timeline" className="timeline-area rn-section-gap bg-color-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <span className="subtitle">TIMELINE</span>
              <h2 className="title">EXPERIENCE</h2>
            </div>
          </div>
        </div>
        <div className="row mt--10 row--25">
          {/* Start Single Timeline  */}
          <div className="col-lg-6 col-md-12 col-12">
            <div className="single-timeline">
              <div className="inner">
                <div className="time">
                  <span>2017 - Current</span>
                </div>
                <Img className="envato-images" fixed={getImages("verisk")} />
                <span className="job">Full Stack Developer</span>
                <p>
                  Full-Stack developer for Lloyds Insurance at London Market.
                  Development of modular and testable code according to
                  Clean-Code and SOLID principles.
                  <br />
                  Technologies used: NetCore, NetFramework, T-SQL, SignalR,
                  Typescript, Angular, Reactjs, SCSS, HTML5, RESTful API,
                  message broker with RabbitMQ and Ms Service Bus.
                  <br />
                  Continuous integration environments, code reviews promoting
                  best practices and design patterns.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Timeline  */}

          {/* Start Single Timeline  */}
          <div className="col-lg-6 col-md-12 col-12">
            <div className="single-timeline">
              <div className="inner">
                <div className="time">
                  <span>2014 - 2017</span>
                </div>
                <Img className="envato-images" fixed={getImages("tym")} />
                <span className="job">Team Lead / Senior Developer</span>
                <p>
                  Responsible for overseeing the day-to-day team work on Agile
                  projects, distributing the workload evenly amongst staff and
                  making sure motivation, quality and performance levels are
                  maintained.
                  <br />
                  Design and development according to Clean-Code and SOLID
                  principles. C# 6, ASP.NET MVC4+, REST Web Services and SQL
                  Optimization.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Timeline  */}

          {/* Start Single Timeline  */}
          <div className="col-lg-6 col-md-12 col-12">
            <div className="single-timeline">
              <div className="inner">
                <div className="time">
                  <span>2013 - 2014</span>
                </div>
                <Img className="envato-images" fixed={getImages("avanade")} />
                <span className="job">Developer / Business Analyst</span>
                <p>
                  Senior developer on web solution to manage bank assets.
                  <br />
                  Analysis and design of new features based on the company
                  requirement, using the agile approach. ASP.NET MVC 4, LINQ,
                  ADO.NET, T-SQL, JQuery, HTML5 y Bootstrap.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Timeline  */}

          {/* Start Single Timeline  */}
          <div className="col-lg-6 col-md-12 col-12">
            <div className="single-timeline">
              <div className="inner">
                <div className="time">
                  <span>2010 - 2013</span>
                </div>
                <Img
                  className="envato-images"
                  fixed={getImages("liscor-lutech")}
                />
                <span className="job">Developer / Business Analyst</span>
                <p>
                  Developer and technical analyst for leasing/financial credit
                  decision and credit scoring solutions. <br />
                  C#4, ASP.NET Webforms, MVC and web services, HTML, Javascript
                  and Bootstrap.
                  <br /> Analysis of workflow applications, financial business
                  logic, data model design and bug fixing. System integration
                  and synchronisation services. On-site technical support and
                  primary contact for international clients.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Timeline  */}
        </div>
      </div>
    </div>
  )
}

export default Timeline;
